<template>
    <div class="main">
        <div class="icons">
            <q-icon
                :name="icon.arrowLeft"
                size="17px"
                v-on:click="$store.dispatch('step/prevStep')"
                style="color: #DCE1E8;"
                class="cursor-pointer"
            />
        </div>
        <p class="title text-center">{{ title }}</p>
        <div>
            <FormInput
                v-if="!phoneCode.waitCode"
                placeholder="Ваше имя"
                v-model="name"
                class="cert-number"
                :layout="!validate.name ? 'danger' : ''"
                lang="en|ru"
                errorMsg="Длина имени менее двух символов"
            />
            <div class="row">
                <div class="col-7">
                    <FormInput
                        placeholder="Номер сертификата"
                        v-model="certNum"
                        :class="['cert-number', {'disabled': phoneCode.waitCode}]"
                        :layout="!validate.certNumber ? 'danger' : ''"
                        lang="en|ru|di"
                        errorMsg="Некорректный номер сертификата"
                    />
                </div>
                <div class="col-1 text-center" style="padding-top: 10px;">#</div>
                <div class="col-4">
                    <FormInput
                        placeholder="12345"
                        v-model="certPin"
                        :class="['cert-number', {'disabled': phoneCode.waitCode}]"
                        :layout="!validate.certNumber ? 'danger' : ''"
                        errorMsg="Цифры после #"
                        lang="en|di"
                    />
                </div>
            </div>
            <div v-if="!phoneCode.waitCode">
                <NumberField
                    placeholder="Введите номер телефона"
                    class="phone-number"
                    :layout="!validate.phone ? 'danger' : ''"
                    errorMsg="Укажите номер"
                />
            </div>
            <div v-else>
                <p class="text-center">
                    Отправили код на номер {{ certData.phone }}.
                    <span class="change-phone cursor-pointer" @click="phoneCode.waitCode = false">Изменить</span>
                </p>
                <FormInput
                    placeholder="Введите код из SMS"
                    v-model="smsCode"
                    class="sms-code"
                    :layout="!validate.phoneCode ? 'danger' : ''"
                    errorMsg="Неверный код"
                    mask="####"
                    maxLength="4"
                />
            </div>
        </div>

        <div>
            <p v-if="!phoneCode.waitCode" class="description text-center">{{ description }}</p>
            <p v-else-if="phoneCode.isBlocked" class="description text-center">{{ phoneCodeDescription }}</p>
            <FormButton v-else
                class="resend-sms"
                width="100%" layout="outline" height="40px" padding="0" font-weight="500"
                @click="getCode"
            >
                Повторно отправить SMS
            </FormButton>
        </div>

        <FormButton
            width="100%" height="40px" padding="0" font-weight="500"
            @click="mainAction"
        >
            Активировать
        </FormButton>
        <p v-if="phoneCode.waitCode" class="sms-error text-center cursor-pointer" @click="support">{{ phoneCode.smsError }}</p>
        <q-dialog v-model="errorCertModal">
            <div class="error-modal">
                <div>
                    <span class="head">Внимание!</span>
                    <q-icon
                        v-close-popup
                        :name="icon.xmark"
                        size="10px"
                        style="color: #A0A9B6; float: right;"
                        class="cursor-pointer"
                    />
                </div>
                <p class="modal-description">Сертификат не найден или уже активирован. Если у вас электронный сертификат, то он уже активен и готов к использованию</p>
                <div class="text-right">
                    <FormButton
                        width="141px" height="40px" padding="0" font-weight="500" layout="outline"
                        class="support"
                        @click="support"
                    >
                        Поддержка
                    </FormButton>
                    <FormButton width="106px" height="40px" padding="0" font-weight="500" v-close-popup>
                        Ок
                    </FormButton>
                </div>
            </div>
        </q-dialog>
    </div>
</template>

<script>
import {fasChevronLeft} from "@quasar/extras/fontawesome-v6";
import FormInput from "@/components/Form/FormInput";
import NumberField from "@/components/Form/NumberField";
import FormButton from "@/components/Form/FormButton";
import {mapGetters} from "vuex";
import validation from "@/store/modules/validation";

export default {
    name: "ActivateCertificate",
    components: {
        FormInput,
        NumberField,
        FormButton
    },
    data() {
        return {
            icon: {
                arrowLeft: null,
            },
            title: 'Активировать сертификат',
            description: 'Телефон поможет нам идентифицировать\nвас в нашем салоне',
            validate: {
                phone: true,
                certNumber: true,
                phoneCode: true,
                name: true
            },
            phoneCode: {
                waitCode: false,
                description: 'Получить новый код можно\nчерез',
                isBlocked: true,
                smsError: 'Не приходит СМС?'
            },
            timer: {
                maxTime: 60,
                currentTime: 0,
                timer: null
            },
            errorCertModal: false,
        };
    },
    computed: {
        ...mapGetters({
            certData: 'data/certData',
            getSmsCode: 'data/phoneCode',
            phoneDigit: 'data/phone'
        }),
        certNumber: {
            get() { return this.certData.certNumber; },
            set(value) {
                const key = 'certNumber';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        certNum: {
            get() { return this.certData.certNum; },
            set(value) {
                const key = 'certNum';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        certPin: {
            get() { return this.certData.certPin; },
            set(value) {
                const key = 'certPin';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        smsCode: {
            get() { return this.getSmsCode; },
            set(value) { this.$store.dispatch('data/setPhoneCode', value); }
        },
        name: {
            get() { return this.certData.customerName; },
            set(value) {
                const key = 'customerName';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        timerTime() {
            const time = String(this.timer.maxTime - this.timer.currentTime);
            return `00:${time.length === 1 ? `0${time}` : time}`;
        },
        phoneCodeDescription() { return `${this.phoneCode.description} ${this.timerTime}` }
    },
    methods: {
        /**
         * Validate certificate num
         * @return Boolean
         */
        validateCertNum() {
            return Boolean(this.certNum.match(/^[A-Za-zа-яА-ЯёЁ]+\d+$/)) && Boolean(this.certNum.length);
        },
        /**
         * Validate certificate pin
         * @return Boolean
         */
        validateCertPin() {
            return Boolean(this.certPin.match(/^[\dA-Z]+$/)) && Boolean(this.certPin.length);
        },
        async mainAction() {

            if (this.phoneCode.waitCode) await this.sendCode();
            else {
                await this.getCode();
            }
        },

        async getCode() {
            this.validate.certNumber = this.validateCertNum() && this.validateCertPin();
            this.validate.phone = validation.phone(this.phoneDigit);
            this.validate.name = this.name.length > 2;
            if (this.validate.phone && this.validate.certNumber && this.validate.name) {
                await this.$store.dispatch('data/findOrCreateGuest');
                let self = this;
                this.$store.dispatch('data/getPhoneCode').then((result) => {
                    self.phoneCode.waitCode = result;
                    if (this.timer.timer) {
                        clearInterval(this.timer.timer);
                    }
                    self.setTimer();
                });
            }
        },
        async sendCode() {
            this.validate.phoneCode = Boolean(this.smsCode.length);
            if (!this.validate.phoneCode) {
                return;
            }
            const isCodeSuccess = await this.$store.dispatch('data/sendPhoneCode');
            if (isCodeSuccess) {
                try {
                    const isCertActivated = await this.$store.dispatch('data/activateCert');
                    if (isCertActivated) {
                        await this.$store.dispatch('step/setStep', 'SuccessActivate');
                    }
                    else {
                        this.validate.certNumber = false;
                        this.phoneCode.waitCode = false;
                        this.errorCertModal = true;
                    }
                } catch {
                    this.validate.phoneCode = false;
                    this.phoneCode.waitCode = false;
                    this.errorCertModal = true;
                }
            } else {
                this.validate.phoneCode = false;
            }
        },
        async setTimer() {
            let self = this;
            this.timer.currentTime = 0;
            this.phoneCode.isBlocked = true;
            this.timer.timer = setInterval(() => {
                if (self.timer.currentTime > self.timer.maxTime) {
                    clearInterval(self.timer.timer);
                    self.phoneCode.isBlocked = false;
                } else {
                    self.timer.currentTime++;
                }
            }, 1000);
        },
        support() { this.$store.dispatch('step/setStep', 'SupportBlock'); }
    },
    created() {
        this.icon.arrowLeft = fasChevronLeft;
    }
}
</script>

<style scoped lang="scss">
.main { padding-bottom: 64px !important; }
.title {
    margin-bottom: 40px !important;
}

.cert-number, .phone-number, .description, .sms-code, .resend-sms {
    margin-bottom: 16px;
}

.cert-number.disabled {
    pointer-events: none;
}

.description {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #A6A6AA;
}

.sms-error {
    margin-top: 16px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $primary-color;
}

.error-modal {
    padding: 32px 32px 24px;
    width: 416px;
    background: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.error-modal .head {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #03081A;
}

.error-modal .modal-description {
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #A0A9B6;
}

.error-modal .support {
    margin-right: 8px;
}

span.change-phone {
    color: $primary-color;
}
</style>