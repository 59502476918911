<template>
    <div class="main">
        <div class="icons">
<!--            <q-icon
                :name="arrowLeft"
                size="17px"
                v-on:click="prevStep"
                style="color: #DCE1E8;"
                class="cursor-pointer"
            />-->
        </div>
        <p class="title text-center">{{ title }}</p>
        <div class="buy">
            <FormButton width="100%" layout="primary" @click="buyCertificate">Купить сертификат</FormButton>
        </div>
<!--        <p class="rule text-center"><a :href="rule.link" target="_blank">{{ rule.text }}</a></p>-->
<!--        <div class="activate text-center">-->
<!--            Чтобы активировать сертификат <span @click="activateCertificate">нажмите здесь</span>-->
<!--        </div>-->
    </div>
</template>

<script>
import {fasChevronLeft} from '@quasar/extras/fontawesome-v6';
import FormButton from "@/components/Form/FormButton";
import {fire} from "@/helpers/analytics";

export default {
    name: "ChooseAction",
    components: {
        FormButton
    },
    data() {
        return {
            title: 'Выберите действие',
            rule: {
                text: 'Правила использования подарочных сертификатов',
                link: 'https://example.com' //todo add link
            }
        }
    },
    methods: {
        prevStep() {
            this.$store.dispatch('step/prevStep');
        },
        activateCertificate() {
            this.$store.dispatch('step/setStep', 'ActivateCertificate');
        },
        buyCertificate() {
            this.$store.dispatch('step/setStep', 'CertificateCatalog');
            fire("buyCertificate", {});
        }
    },
    created() {
        this.arrowLeft = fasChevronLeft;
    }
}
</script>

<style scoped lang="scss">
.main {
    padding-bottom: 64px;
}

.main>.icons {
    height: 12px;
    padding: 0 6px;
    margin-bottom: 12px;
}

p.title {
    margin-bottom: 40px;
}

p:not(.title) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

div.buy {
    margin-bottom: 16px;
}

p.rule { margin-bottom: 16px; }

p.rule>a {
    text-decoration: none;
    color: $primary-color;
}

div.activate>span {
    color: $primary-color;
    cursor: pointer;
}
</style>