<template>
    <div class="main">
        <p class="title text-center">{{ title }}</p>
        <p class="balance text-center" v-if="payDoc.measure ==='time' ">Баланс {{ getTimeMeasure() }}</p>
        <p class="balance text-center" v-else>Баланс {{ payDoc.balance }} руб</p>
        <p class="validity text-center">Срок действия до {{ validityDate }}</p>
        <p class="thanks text-center">{{ thanksText }}</p>
        <FormButton
            width="100%" height="40px" padding="0" font-weight="500"
            @click="onlineRecord"
        >
            Записаться
        </FormButton>
    </div>
</template>

<script>
import FormButton from "@/components/Form/FormButton.vue";
import {mapGetters} from "vuex";

export default {
    name: "SuccessActivate",
    components: {FormButton},
    data() {
        return {
            title: 'Подарочный сертификат активирован',
            thanksText: "Спасибо, что выбрали maitai"
        };
    },
    computed: {
        ...mapGetters({
            payDoc: 'data/payDoc'
        }),
        validityDate() {
            const date = new Date(this.payDoc.validity);
            const month = date.getMonth() + 1;
            return `${date.getDate()}.${month < 10 ? `0${month}` : month}.${date.getFullYear()}`;
        }
    },
    methods: {
        onlineRecord() {
            window.location.replace(process.env.VUE_APP_ONLINE_RECORD_LINK);
        },
      getTimeMeasure(){
        let time = this.payDoc.balance
        let minutes= ((time - Math.floor(time))*60).toFixed(0)
        let hourText = Math.floor(time) + " ч."
        let minutesText=''
        if (minutes !== '0'){
          minutesText= ' '+minutes + " мин"
        }
        return hourText + minutesText
      }
    }
}
</script>

<style scoped>
.balance { margin-top: 40px; }
.balance, .validity {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #A6A6AA;
    margin-bottom: 8px;
}
.thanks {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3D1152;
}
</style>