function defaultState() {
    return {
        certTypes: [
            { id: 0, name: 'Электронный' },
            { id: 1, name: 'Пластиковый' },
        ],
        deliveryTypes: [
            { id: 'pickup', name: 'Самовывоз' },
            { id: 'courier', name: 'Курьером' },
        ],
        deliveryPrice: 300,
        freeDeliveryMinSum: 3500000
    };
}

/**
 * Модуль с данными для корзины покупок
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getState(state) { return state; },

        /**
         * Get certificate types
         * @param {Object} state
         * @return Array
         */
        certTypes(state) { return state.certTypes; },

        /**
         * Get delivery types
         * @param {Object} state
         * @return Array
         */
        deliveryTypes(state) { return state.deliveryTypes; },

        deliveryPrice(state) { return state.deliveryPrice; },
        freeDeliveryMinSum(state) { return state.freeDeliveryMinSum; },
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },
    }
}