<template>
    <div class="cart-list-item">
        <div>
            <div :class="['image', type]"></div>
            <div class="name"><span>{{ name }}</span></div>
            <div class="count">{{ count ? `x${count}` : '' }}</div>
            <div class="price-time">
                <div class="price">{{ price }} руб</div>
                <div class="time">{{ time }}</div>
            </div>
            <div class="delete text-right" v-if="type !== 'delivery'">
                <q-icon
                    :name="icon.xmark"
                    size="15px"
                    v-on:click="removeItem"
                    style="color: #DCE1E8;"
                    class="cursor-pointer"
                />
            </div>
            <div class="delivery-info text-right" v-else>
                <InfoIcon
                    color="#4E4E4E" width="15px"
                    :popupText="'Для уточнения стоимости доставки за пределами города - звоните по номеру 97-00-77'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { fasXmark } from '@quasar/extras/fontawesome-v6';
import InfoIcon from "@/components/Icon/InfoIcon";
import {mapGetters} from "vuex";

export default {
    name: "CartListItem",
    components: { InfoIcon },
    data() {
        return {
            icon: {
                xmark: null
            },
        }
    },
    computed: {
        ...mapGetters({
            freeDeliveryMinSum: 'cart/freeDeliveryMinSum'
        })
    },
    props: {
        id: String,
        name: String,
        time: String,
        count: Number,
        price: String,
        type: String // service|certificate|promo|delivery
    },
    methods: {
        removeItem() {
            const type = this.type;
            const itemId = this.id;
            this.$store.dispatch('data/removeCartItem', {type, itemId});
        }
    },
    created() {
        this.icon.xmark = fasXmark;
    }
}
</script>

<style scoped lang="scss">
.cart-list-item {
    padding: 8px 0;
    display: table;
}

.cart-list-item>div { display: table-row }

.image, .name, .count, .price-time, .delete, .delivery-info {
    display: table-cell;
    height: 50px;
    max-height: 50px;
}

.image {
    width: 50px;
    min-width: 50px;
}

.image.service {
    background: url("@/assets/img/CardBackground.png") no-repeat;
    background-size: cover;
}
.image.certificate {
    background: url("@/assets/img/moneyCertificateBackground.jpg") no-repeat;
    background-size: cover;
}
.image.promo {
    background: url("@/assets/img/moneyCertificateBackground.jpg") no-repeat;
    background-size: cover;
}

.image.delivery {
    background: url("@/assets/img/delivery.png") no-repeat;
    background-size: cover;
}

.name, .count {
    vertical-align: middle;
    line-height: 20px;
}

.name {
    width: 198px;
    padding: 0 16px;
    font-weight: 700;
    font-size: 14px;
    color: #3A3A3A;
}
.name>span {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.count {
    width: 30px;
    font-weight: 500;
    font-size: 16px;
    text-align: right;
    color: #3A3A3A;
}

.price-time {
    width: 86px;
    padding-left: 16px;
    vertical-align: middle;
}
.price-time>div {
    text-align: right;
    opacity: 0.9;
    line-height: 20px;
}
.price-time .price {
    font-weight: 700;
    font-size: 16px;
    align-items: center;
    color: #3A3A3A;
}
.price-time .time {
    font-weight: 500;
    font-size: 14px;
    color: #5A305D;
}

.delete, .delivery-info {
    width: 28px;
    vertical-align: middle;
}

@media screen and (max-width: $main-width) {
    .name {
        padding: 0 12px;
    }

    .price-time .price {
        font-size: 12px;
    }
}
</style>